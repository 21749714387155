import { useEffect, useState } from 'react';

import APIClient from 'src/lib/services/APIService';
import { User } from 'src/models/user';

interface useFetchUserReturnValue {
  user: User;
  loading: boolean;
  error: string;
}

/**
 * Hook which returns user details for the current user
 * @param tokenExists boolean value if the token is stored
 * in the local storage
 * @returns (user, loading, error) object
 */
export function useFetchUser(tokenExists: boolean): useFetchUserReturnValue {
  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    setError('');

    if (!tokenExists) {
      setLoading(false);
      return;
    }

    APIClient.getCurrentUser()
      .then((resp) => {
        setUser(resp.data);
      })
      .catch((err) => {
        setError('Failed to fetch user details');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [tokenExists]);

  return { user, loading, error };
}
