import { OpenPortsCount, TLSCertificate } from './network';

export enum assetType {
  IPAdress = 'IP Address',
  Domain = 'Domain',
  IPSubnet = 'IP Subnet',
  Keyword = 'Keyword',
  VIPEmail = 'VIP Email'
  // Username = 'Username'
}

export interface AssetBase {
  id?: number;
  dbCreatedAt?: string;
  dbUpdatedAt?: string;
  processingIgnored?: boolean;
  labels: string[];
  timesScanned?: number;
  vulnerabilities?: number;
  value: string;
  assetType: string;
  malwareScan: boolean;
  phishingScan: boolean;
  darkWebScan: boolean;
  breachScan: boolean;
  infoLeakageScan: boolean;
}

export interface AssetDetails extends AssetBase {
  vulnerabilitiesTotal: number;
}

export interface DetectedSoftware {
  product: string;
  version: string;
  cpe: string;
}

export interface IpAddressOrganization {
  id: number;
  name: string;
  whoisOrgId?: number;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}
export interface IPAddressDomain {
  id: number;
  value: string;
}
export interface IPAddressAsset extends AssetDetails {
  country: string;
  city: string;
  regionCode: string;
  areaCode: string;
  latitude: number;
  longitude: number;
  isp: string;
  asn: string;
  ipAddressOrganization: IpAddressOrganization;
  netRange: string;
  cidr: string;
  netType: string;
  netHandle: string;
  parent: string;
  netName: string;
  software: DetectedSoftware[];
  domains: IPAddressDomain[];
}

export interface HttpDetails {
  id: number;
  statusCode: number;
  webTitle: string;
  metaDescription: string;
  metaKeywords: string;
  metaAuthor: string;
  webScreenshot: string;
}

export interface DomainRegistrar {
  name: string;
  url: string;
  email: string;
  phone: string;
  ianaId: string;
  abuseContactEmail: string;
  abuseContactPhone: string;
  whoisServer: string;
}
export interface whoisDetails {
  registrar: DomainRegistrar;
  registrantName: string;
  registrantOrganization: string;
  registrantStreet: string;
  registrantCity: string;
  registrantState: string;
  registrantPostalCode: string;
  registrantCountry: string;
  registrantPhone: string;
  registrantFax: string;
  registrantEmail: string;
  creationDate: string;
  expirationDate: string;
  updatedDate: string;
}

export interface DNSDetails {
  aRecords: IPAddressAsset[];
  mxRecord: string;
  cnameRecords: string[];
  txtRecords: string[];
  nsRecords: string[];
  soaRecord: string;
}

export interface Domain extends AssetDetails {
  httpDetails: HttpDetails;
  whoisDetails: whoisDetails;
  dnsDetails: DNSDetails;
  software: DetectedSoftware[];
  tlsCertificates: TLSCertificate[];
  ports: OpenPortsCount[];
  isReachable: boolean;
}

export interface IPSubnet {
  id: number;
  ipSubnet: string;
}

export interface Keyword extends AssetDetails {}

export interface Label {
  id?: number;
  value: string;
}

export interface AssetDateTrendCount {
  date: string;
  total: number;
}
export interface AssetTypeCount {
  domain: number;
  keyword: number;
  ipAddress: number;
}

export interface AssetLocation {
  latitude: number;
  longitude: number;
  assetValue: string;
  vulnerabilitiesTotal: number;
}

export interface AssetGraphEdge {
  source: string;
  target: string;
}

export interface AssetGraphNode {
  id: string;
  value: string;
  type: string;
}

export interface AssetGraph {
  nodes: AssetGraphNode[];
  edges: AssetGraphEdge[];
}
