import { lazy } from 'react';
import LazyLoader from 'src/components/LazyLoader';

const ActivelyExploitedCVEs = LazyLoader(
  lazy(() => import('src/content/ActivelyExploitedCVEs'))
);

const ActivelyExploitedCVEDetails = LazyLoader(
  lazy(
    () =>
      import('src/content/ActivelyExploitedCVEs/ActivelyExploitedCVEDetails')
  )
);

export const activelyExploitedCVERoutes = [
  {
    path: 'actively-exploited-cves',
    element: <ActivelyExploitedCVEs />
  },
  {
    path: 'actively-exploited-cves/:activelyExploitedCVEId',
    element: <ActivelyExploitedCVEDetails />
  }
];
