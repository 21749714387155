import { useContext, useEffect } from 'react';

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import {
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  Link,
  Tooltip,
  styled,
  useTheme
} from '@mui/material';
import { SidebarContext } from 'src/contexts/SidebarContext';

import { UserContext } from 'src/contexts/UserContext';
import { useBreadcrumbs } from 'src/lib/hooks';
import PlanExpired from '../PlanExpired';
import HeaderUserbox from './Userbox';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        backdrop-filter: blur(3px);
        position: fixed;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const user = useContext(UserContext);
  const isPricingPlanExpired =
    new Date(user.organization.pricingPlan.endDate) < new Date();

  const { items, isVisible } = useBreadcrumbs('', false, true);

  useEffect(() => {
    if (!isPricingPlanExpired) {
      // no need to add listener if pricing plan is not expired
      return;
    }
    const headerElement = document.getElementById('header-bar-id');
    const initialOffsetTop = headerElement.offsetTop;

    // add event listener to window to listen for scroll, so that we can
    // have sticky header even if the error message is shown
    window.addEventListener('scroll', () => {
      // if error message is no longer visible, put normal header to top
      if (window.scrollY > initialOffsetTop) {
        // if error message is not longer visible, put normal header to top
        headerElement.style.top = '0';
      } else {
        // error message is visible, we should top style to the visible height of the message
        headerElement.style.top = `${initialOffsetTop - window.scrollY}px`;
      }
    });
  }, []);

  return (
    <>
      {isPricingPlanExpired && <PlanExpired id="plan-expired-id" />}
      <HeaderWrapper display="flex" alignItems="center" id="header-bar-id">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <Box
              alignItems="center"
              sx={{
                display: {
                  xs: 'none',
                  lg: 'flex'
                }
              }}
            >
              {isVisible && (
                <Breadcrumbs
                  sx={{
                    fontSize: '16px'
                  }}
                >
                  {items?.length > 0 &&
                    items.slice(0, 2).map((item, ix) => {
                      return (
                        <Link
                          key={ix}
                          underline="hover"
                          color={
                            item.url === window.location.href
                              ? 'primary'
                              : 'secondary'
                          }
                          href={item.url}
                          sx={
                            item.url === window.location.href
                              ? {
                                  pointerEvents: 'none'
                                }
                              : {}
                          }
                        >
                          {item.name.slice(0, 30) +
                            (item.name.length > 30 ? '...' : '')}
                        </Link>
                      );
                    })}
                  {items.length > 3 && (
                    <Link sx={{ pointerEvents: 'none' }}>...</Link>
                  )}
                  {items.length > 2 &&
                    items.slice(-1).map((item, ix) => {
                      return (
                        <Link
                          key={ix}
                          underline="hover"
                          color={
                            item.url === window.location.href
                              ? 'primary'
                              : 'secondary'
                          }
                          href={item.url}
                          sx={
                            item.url === window.location.href
                              ? {
                                  pointerEvents: 'none'
                                }
                              : {}
                          }
                        >
                          {item.name.slice(0, 30) +
                            (item.name.length > 30 ? '...' : '')}
                        </Link>
                      );
                    })}
                </Breadcrumbs>
              )}
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center">
              <HeaderUserbox />
              <Box
                component="span"
                sx={{
                  ml: 2,
                  display: { lg: 'none', xs: 'inline-block' }
                }}
              >
                <Tooltip arrow title="Toggle Menu">
                  <IconButton color="primary" onClick={toggleSidebar}>
                    {!sidebarToggle ? (
                      <MenuTwoToneIcon fontSize="small" />
                    ) : (
                      <CloseTwoToneIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      ></Stack> */}
      </HeaderWrapper>
    </>
  );
}

export default Header;
