import axios from 'axios';
import { Label } from 'src/models/asset';
import { Threat, threatStatus } from 'src/models/threat';

import { CreateAPIKey } from 'src/models/api_key';
import { User } from 'src/models/user';
import { AssetQueryParams, QueryParams, ThreatQueryParams } from '../constants';
import StorageService from './StorageService';

const API_URL = process.env.REACT_APP_API_URL;

const MODIFYING_REQUEST_HEADERS = {
  // Overwrite Axios's automatically set Content-Type
  'Content-Type': 'application/json'
};

const LOGIN_URL = `users/auth/token/`;

export const APIClient = axios.create({ baseURL: API_URL });

APIClient.interceptors.request.use(function (config) {
  if (StorageService.tokenExists()) {
    config.headers['Authorization'] = `Bearer ${
      StorageService.getAuthToken().accessToken
    }`;
  }
  return config;
});

APIClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !originalRequest._refresh &&
      originalRequest?.url !== LOGIN_URL
    ) {
      originalRequest._retry = true;

      const refreshConfig = {
        baseURL: API_URL,
        headers: MODIFYING_REQUEST_HEADERS
      };
      refreshConfig['_retry'] = true;

      const refreshToken = StorageService.getAuthToken().refreshToken;

      try {
        const refreshResponse = await axios.post(
          `users/auth/token/refresh/`,
          JSON.stringify({ refresh: refreshToken }),
          refreshConfig
        );

        await StorageService.setAuthToken({
          accessToken: refreshResponse.data['access'],
          refreshToken: refreshToken
        });

        return await axios({
          ...originalRequest,
          _retry: true,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${refreshResponse.data['access']}`
          }
        });
      } catch (refreshError) {
        StorageService.removeAuthToken();
        if (window.location.pathname !== `login`) {
          window.location.pathname = `login`;
        }
        return Promise.reject(refreshError);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

/**
 * Sends POST request to the given url and adds object as JSON
 * to the request body.
 * @param url path to the resource
 * @param object object to be converted to JSON body
 * @returns
 */
export function axiosPost<T>(url: string, object: T) {
  return APIClient.post<T>(url, JSON.stringify(object), {
    headers: MODIFYING_REQUEST_HEADERS
  });
}

export function axiosPut<T>(url: string, object: T) {
  return APIClient.put<T>(url, JSON.stringify(object), {
    headers: MODIFYING_REQUEST_HEADERS
  });
}

export function axiosPatch<T>(url: string, object: T) {
  return APIClient.patch<T>(url, JSON.stringify(object), {
    headers: MODIFYING_REQUEST_HEADERS
  });
}

function axiosDelete<T>(url: string, object: T) {
  return APIClient.delete<T>(url, {
    data: JSON.stringify(object),
    headers: MODIFYING_REQUEST_HEADERS
  });
}

const addQueryParamToUrl = (url, queryKey, queryValue) => {
  if (!queryKey) return url;

  if (url.includes('?')) {
    // add just one more query param
    url = url + `&${queryKey}=${queryValue}`;
  } else {
    // this is first query param
    url = url + `?${queryKey}=${queryValue}`;
  }

  return url;
};

class APIService {
  GETRequestWithQueryString<RV>(path: string, queryParams: QueryParams) {
    Object.keys(queryParams).forEach((key) => {
      path = addQueryParamToUrl(path, key, queryParams[key]);
    });

    return APIClient.get<RV>(path);
  }

  getAssets(queryParams: AssetQueryParams) {
    return this.GETRequestWithQueryString<any>('assets/', queryParams);
  }

  getAssetsCounts() {
    return APIClient.get('assets/counts/monitored/');
  }

  updateAssetLabels(assetId: number, labels: Label[]) {
    return axiosPatch(`assets/${assetId}/`, { labels });
  }

  deleteAssets(assetData: any) {
    return axiosDelete('assets/bulk/', assetData);
  }

  addAssetsToMonitored(
    assetData: {
      assetId: number;
      processingIgnored: boolean;
      malwareScan: boolean;
      phishingScan: boolean;
      darkWebScan: boolean;
      breachScan: boolean;
      infoLeakageScan: boolean;
    }[]
  ) {
    return axiosPatch('assets/bulk/', assetData);
  }

  registerUser(user: any) {
    return axiosPost<any>(`core/auth/register/`, user);
  }

  loginUser(user: any) {
    return axiosPost<any>(LOGIN_URL, user);
  }

  getCurrentUser() {
    return APIClient.get<User>('users/current/');
  }

  getLabels() {
    return APIClient.get<Label[]>('labels/');
  }

  getAllThreats(queryParams: ThreatQueryParams) {
    // TODO pagination response interface
    // return APIClient.get<any>(`threat/all/?page=${page}&pageSize=${pageSize}`);
    return this.GETRequestWithQueryString<any>('threats/', queryParams);
  }

  getThreat(threatType: string, threat_id: Threat['id']) {
    return APIClient.get<any>(`threats/${threatType}/${threat_id}/`);
  }

  getThreatFilters(filter: string, search: string) {
    return APIClient.get<any>(`threats/filters/${filter}/?search=${search}`);
  }

  updateThreat(threatId: number, threatData: { threatStatus: threatStatus }) {
    return axiosPatch(`threats/${threatId}/`, threatData);
  }

  getImageURL(imagePath: string): string {
    return `${API_URL}${imagePath}`;
  }

  getAPIKeys(queryParams: QueryParams) {
    return this.GETRequestWithQueryString<any>(`api-keys/`, queryParams);
  }

  postAPIKey(apiKey: CreateAPIKey) {
    return axiosPost(`api-keys/`, apiKey);
  }

  deleteAPIKey(id: number) {
    return axiosDelete(`api-keys/${id}/`, undefined);
  }
}

export default new APIService();
