import { useEffect, useState } from 'react';
import APIClient from 'src/lib/services/APIService';

export interface useFetchCountsReturnValue {
  monitored: number;
  suggested: number;
  monitoredIpAddresses: number;
  monitoredIpAddressesDarkWeb: number;
  monitoredDomains: number;
  monitoredDomainsMalware: number;
  monitoredDomainsPhishing: number;
  monitoredDomainsDarkWeb: number;
  monitoredDomainsBreach: number;
  monitoredDomainsInfoLeakage: number;
  monitoredKeywords: number;
  monitoredKeywordsPhishing: number;
  monitoredKeywordsDarkWeb: number;
  monitoredVIPEmails: number;
  monitoredVIPEmailsDarkWeb: number;
  monitoredVIPEmailsBreach: number;
}

export function useFetchAssetMonitoredCounts(): useFetchCountsReturnValue {
  const [counts, setCounts] = useState({} as useFetchCountsReturnValue);

  useEffect(() => {
    APIClient.getAssetsCounts().then((resp) => setCounts(resp.data));
  }, []);

  return counts;
}
