import { useState } from 'react';
import APIClient from 'src/lib/services/APIService';
import { AssetBase } from 'src/models/asset';

interface useManageAssetReturnValue {
  deleteAssets: (assetsToDelete: any[]) => void;
  addAssetsToMonitored: (assets: AssetBase[]) => void;
  loading: boolean;
  error: string;
}

export function useManageAsset(
  refreshOnSuccess = false
): useManageAssetReturnValue {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const deleteAssets = (assetsToDelete: any[]) => {
    setLoading(true);
    setError('');
    APIClient.deleteAssets(assetsToDelete)
      .then(() => {
        if (refreshOnSuccess) window.location.reload();
      })
      .catch((err) =>
        setError('There was an error while trying to delete asset')
      )
      .finally(() => setLoading(false));
  };

  const addAssetsToMonitored = (assets: AssetBase[]) => {
    setLoading(true);
    setError('');
    const data = assets.map((asset) => ({
      assetId: asset.id,
      processingIgnored: false,
      malwareScan: asset.malwareScan,
      phishingScan: asset.phishingScan,
      darkWebScan: asset.darkWebScan,
      breachScan: asset.breachScan,
      infoLeakageScan: asset.infoLeakageScan
    }));
    APIClient.addAssetsToMonitored(data)
      .then(() => {
        if (refreshOnSuccess) window.location.reload();
      })
      .catch((err) => {
        let errorMessage = undefined;
        if (err.response?.data?.error) {
          errorMessage = err.response.data.error;
        } else if (err.response?.data?.errors?.length) {
          errorMessage = err.response.data.errors[0].detail;
        } else {
          errorMessage =
            'There was an error while trying to add assets to monitored.';
        }

        setError(errorMessage);
      })
      .finally(() => setLoading(false));
  };

  return { loading, error, deleteAssets, addAssetsToMonitored };
}
